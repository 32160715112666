import React from 'react'
import { assets } from '@/helpers/common'

function WhyDifferent() {
  return (
    <>
      <section className='why-different-section bg-white-ice py-14 px-20 lg-mx:px-10 sm-mx:pt-8 sm-mx:pb-8 sm-mx:px-0'>
        <div className='container-fluid'>
          <div className='row justify-between'>
            <div className='w-6/12 md-mx:w-full text-right flex justify-end md-mx:justify-center items-end lg:items-center md-mx:mb-5 left-col'>
              <img
                className='inline-block'
                src={assets('images/why-we-are-different.png')}
                alt='Why We Are Different?'
              />
            </div>
            <div className='w-6/12 md-mx:w-full right-col md-mx:text-center'>
              <div className='section-title block md-mx:text-center'>
                <p className='text-xl text-primary mb-3'>Your One Stop Place For Storage And Shipping Services</p>
                <h2>Why We Are Different?</h2>
              </div>
              <ul className='list-style-one md-mx:inline-block md-mx:text-left'>
                <li className='text-lg mb-3'>
                  Planning to moving another city or new home? We can store <br />
                  and ship your items.
                </li>
                <li className='text-lg mb-3'>
                  Do not need a whole truck? We can ship even you have a <br />
                  single luggage!
                </li>
                <li className='text-lg mb-3'>Need to ship internationally? We got you covered!</li>
                <li className='text-lg mb-3'>
                  We have strategic partnership with two international couriers <br />
                  FedEx and DHL.
                </li>
                <li className='text-lg mb-3'>
                  You do not need to visit our storage center to store your <br />
                  luggage and boxes. We pick up and deliver.
                </li>
                <li className='text-lg mb-3'>
                  You do not need to pay for the extra space in the storage. <br />
                  Pay for your luggage and boxes only.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyDifferent
