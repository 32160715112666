import React from 'react'
import { motion } from 'framer-motion'
import Link from 'next/link'
import { assets } from '@/helpers/common'
import FeatureBox from '@/components/subComponents/FeatureBox'

/*import featureOne from '/public/images/home_feature_01.png'
import featureTwo from '/public/images/home_feature_02.png'
import featureThree from '/public/images/home_feature_03.png'
import featureFour from '/public/images/home_feature_04.png'*/

export const uniqueId = string => {
  return string + Math.floor(Math.random() * 10000) + Date.now()
}

export const titleWithDetails = {
  features: [
    {
      title: 'Prepare Your Items',
      icon: assets('images/home_feature_01.png'),
      content:
        'Are you changing home? Back to office? Back to school? Back to home? Going to Business Trade Show? A player? Moving to another city? No matter, we can store your belongings. Simply pack your luggage, boxes, golf clubs, ski & snowboards.'
    },
    {
      title: 'We Pick up or You Drop off',
      icon: assets('images/home_feature_02.png'),
      content:
        'We have a partnership with FedEx and DHL couriers. So you just prepare your luggage and boxes, and we will arrange pickup for you, or you can simply drop them off at your nearest FedEx / DHL location.'
    },
    {
      title: 'We Store Your Items',
      icon: assets('images/home_feature_03.png'),
      content:
        'Only we are providing smart self storage for you. We can store even one small piece of luggage to as many as you want. Moreover, we offer six (06) months of FREE storage services for you. So you do not need to come to our facility anymore. '
    },
    {
      title: 'We Ship',
      icon: assets('images/home_feature_04.png'),
      content:
        'We brought the smartest solution for shipping. We have domestic and international shipping services. We can ship to more than 220 countries around the world. So you can ship your items to wherever and whenever you need them.'
    }
  ],
  solutions: [
    {
      title: 'Smart and Secure Storage',
      icon: assets('images/solution_01.png'),
      content:
        'We can store even a small luggage! You do not need to hire big space from traditional storage service providers! That means you are not going to pay for the extra space!'
    },
    {
      title: 'We Ship Anywhere',
      icon: assets('images/solution_02.png'),
      content:
        'No Matter you are going to another city or country! We ship inside USA and worldwide. We can save up to 70% on shipping fees as we have partnership with FedEx and DHL'
    }
  ],
  storage_features: [
    {
      title: 'Home pickup & delivery available',
      ship_and_storage: true,
      clutter: true,
      public_storage: true
    },
    {
      title: 'Access at local facility',
      ship_and_storage: true,
      clutter: true,
      public_storage: true
    },
    { title: 'Packing supplies available', ship_and_storage: true, clutter: true, public_storage: false },
    {
      title: 'Online photo catalog',
      ship_and_storage: true,
      clutter: true,
      public_storage: false
    },
    { title: '24/7 customer Service', ship_and_storage: true, clutter: false, public_storage: false },
    {
      title: 'International Shipping',
      ship_and_storage: true,
      clutter: false,
      public_storage: false
    },
    { title: 'Store Single item', ship_and_storage: true, clutter: false, public_storage: false },
    {
      title: '6 Months Free Storage',
      ship_and_storage: true,
      clutter: false,
      public_storage: false
    },
    { title: 'Up to 70% savings on shipping fees', ship_and_storage: true, clutter: false, public_storage: false },
    {
      title: 'Partnership With FedEx and DHL',
      ship_and_storage: true,
      clutter: false,
      public_storage: false
    }
  ]
}

export const getBanner = () => {
  const desktopBanner = (
    <div className='container mx-auto mt-8 md:mt-4 md:mb-8 sm-mx:mt-0'>
      <div className='row sm-mx:flex-col-reverse'>
        <div className='w-6/12 sm-mx:w-full sm-mx:hidden'>
          <motion.div
            animate={{ scale: 1, x: 0, opacity: 1 }}
            transition={{ duration: 1.0 }}
            initial={{ scale: 0.2, x: -400, opacity: 0 }}
          >
            <div className='banner-content sm-mx:flex sm-mx:flex-col sm-mx:items-center'>
              <h1 className='text-4xl font-normal text-primary-gray mt-5 mb-10 md-mx:mb-4 sm-mx:text-center'>
                <span className='text-5xl font-bold text-secondary'>Smart </span>
                <span className='text-5xl font-bold text-primary-orange'>Storage &</span> <br />
                Worldwide Shipping
              </h1>
              <ul className='list-style-one sm-mx:inline-block'>
                <li className='text-primary font-semibold text-xl mb-1'>6 Months Free Storage</li>
                <li className='text-primary font-semibold text-xl mb-1'>Free Insurance up to $250</li>
                <li className='text-primary font-semibold text-xl mb-1'>1 Small box To 100 Large box Storage</li>
                <li className='text-primary font-semibold text-xl mb-1'>Storage Services With Freedom</li>
              </ul>
              <Link href={`/book-storage-facility`} className='btn btn-primary get-started shadow mt-2 mb-7'>
                Get Started
              </Link>
            </div>
          </motion.div>
        </div>
        <div className='w-6/12 sm-mx:w-full md-mx:flex md-mx:flex-col md-mx:justify-center'>
          <motion.div
            className='-mb-10 md-mx:mb-0 banner-image'
            animate={{ scale: 1, x: 0, opacity: 1 }}
            transition={{ duration: 1.0 }}
            initial={{ scale: 0.2, x: 400, opacity: 0 }}
          >
            <img className='banner-right-image' src={assets('images/banner-storage.png')} alt={'Storage'} />
          </motion.div>
        </div>
      </div>
    </div>
  )
  const tabBanner = desktopBanner
  const mobileBanner = desktopBanner
  const bannerAfter = (
    <div className='banner-after-content hidden sm-mx:block'>
      <div className='container mx-auto'>
        <div className='row sm-mx:flex-col-reverse'>
          <div className='w-6/12 sm-mx:w-full'>
            <motion.div
              animate={{ scale: 1, x: 0, opacity: 1 }}
              transition={{ duration: 1.0 }}
              initial={{ scale: 0.2, x: -400, opacity: 0 }}
            >
              <div className='banner-content sm-mx:flex sm-mx:flex-col sm-mx:items-center'>
                <h1 className='text-2xl text-primary-gray mt-5 mb-10 md-mx:mb-4 sm-mx:text-center'>
                  <span className='text-3xl font-bold text-secondary'>Smart </span>
                  <span className='text-3xl font-bold text-primary-orange'>Storage &</span> <br />
                  Worldwide Shipping
                </h1>
                <ul className='list-style-one sm-mx:inline-block'>
                  <li className='font-semibold text-md mb-1'>6 Months Free Storage</li>
                  <li className='font-semibold text-md mb-1'>Free Insurance up to $250</li>
                  <li className='font-semibold text-md mb-1'>1 Small box To 100 Large box Storage</li>
                  <li className='font-semibold text-md mb-1'>Storage Services With Freedom</li>
                </ul>
                <Link href={`/book-storage-facility`} className='btn btn-primary get-started shadow mt-2'>
                  Get Started
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  )

  return {
    desktopBanner,
    tabBanner,
    mobileBanner,
    bannerAfter
  }
}

export const features_output = titleWithDetails.features.map((item, index) => {
  return (
    <div key={index} className='w-3/12 lg:w-3/12 md:w-6/12 sm-mx:w-6/12 xs:w-full px-5 text-center'>
      <FeatureBox title={item.title} icon={item.icon} content={item.content} />
    </div>
  )
})

export const solutions_output = titleWithDetails.solutions.map((item, index) => {
  let oddClass = index / 2 > 0 ? 'left-icon odd' : 'left-icon'

  return (
    <div key={index} className='w-6/12 md-mx:w-full px-3 xs:px-0 text-left'>
      <FeatureBox className={oddClass} title={item.title} icon={item.icon} content={item.content} />
    </div>
  )
})

export const storage_features_output = titleWithDetails.storage_features.map((item, index) => {
  return (
    <tr key={index}>
      <td>{item.title}</td>
      <td>
        <img
          src={item.ship_and_storage ? assets('images/checked_icon.png') : assets('images/close_icon.png')}
          alt={item.title}
        />
      </td>
      <td>
        <img
          src={item.clutter ? assets('images/checked_icon.png') : assets('images/close_icon.png')}
          alt={item.title}
        />
      </td>
      <td>
        <img
          src={item.public_storage ? assets('images/checked_icon.png') : assets('images/close_icon.png')}
          alt={item.title}
        />
      </td>
    </tr>
  )
})
