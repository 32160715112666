import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Autoplay } from 'swiper/modules'

import 'swiper/css'
import 'swiper/css/pagination'

function Reviews() {
  return (
    <>
      <section className='what-people-say-section pt-16 sm-mx:pt-6'>
        <div className='container'>
          <div className='w-full text-left sm-mx:text-center'>
            <div className='section-title effect effect-after'>
              <h2>What People Say About Us</h2>
            </div>
          </div>
          <div className='row-auto pt-12'>
            <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{ clickable: true }}
              className={`client-say-carousel`}
              spaceBetween={50}
              slidesPerView={3}
              loop={true}
              autoplay={true}
              breakpoints={{
                375: {
                  slidesPerView: 1
                },
                768: {
                  slidesPerView: 2
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50
                }
              }}
            >
              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>LV</span>
                    </span>
                    <h5 className='user-name'>Leen Vlogs</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      I have worked with Ship And Storage for two years now and i will always choose them to use smart
                      storage, because of the amazing customer service provided. Thanks to Leo, who made my experience
                      outstanding with his support, patience, professionalism and friendliness.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>SV</span>
                    </span>
                    <h5 className='user-name'>Silvla Villas Boas</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      I’m very satisfied, I sent luggage to their smart storage they arrived perfect, we have some
                      delays from FedEx, but nothing bad . Thanks for all attention and support.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='far fa-star'></i>
                    <span className='rating-number'>4.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>TT</span>
                    </span>
                    <h5 className='user-name'>Tina Tintin</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      I had the best experience with Ship And Storage to ship and especially with representative adham.
                      My shipment arrived on time and they called me to inform me.Anyone wanting to store and ship
                      overseas this is the best storage & shipment service.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>WI</span>
                    </span>
                    <h5 className='user-name'>Wishes</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      <strong className='underline font-semibold text-secondary'>Excellent service!</strong> Convenient,
                      Reliable, and Affordable. I use it for almost all my domestic trips and delivery is always on time
                      and saves me and my family check-in time at the airport. Highly recommend.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>TG</span>
                    </span>
                    <h5 className='user-name'>Tammy Grigsby</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      <strong className='underline font-semibold text-secondary'>Service was great</strong> Service was
                      great! Gave me answer immediately
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>TA</span>
                    </span>
                    <h5 className='user-name'>Tasneem</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      <strong className='underline font-semibold text-secondary'>
                        Great service! Definitely recommend!
                      </strong>{' '}
                      I just placed my order after speaking with a representative (Adham) who was very helpful. From
                      other reviews I feel optimistic and will definitely add another review once my items are sent and
                      have arrived.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>RG</span>
                    </span>
                    <h5 className='user-name'>Raquel Garcla</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      <strong className='underline font-semibold text-secondary'>Great online service!</strong> Online
                      service is good. I have a question about labelling and the online question service helped me! It
                      was fast and really really useful. They answered all my questions.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>BJ</span>
                    </span>
                    <h5 className='user-name'>Bonlta Jennings</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      <strong className='underline font-semibold text-secondary'>
                        Great service for a great price!
                      </strong>{' '}
                      I used Ship And Storage for the first time while closing the apartment my son had used for his
                      college studies. I had far too many pieces of luggage to carry on the plane home, so reached out
                      to Luggage to Ship. It was very easy to use the website, cost efficient, delivery within 3 days,
                      and the customer service was excellent. I will definitely be using their service in the future
                      when I have extra luggage or just need to ship things domestically or internationally.
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>JH</span>
                    </span>
                    <h5 className='user-name'>Johannes Hadiono</h5>
                    <p className='user-role'>ID</p>
                  </div>
                  <div className='content'>
                    <p>
                      Great customer service and excellent information! Really very helpful and enjoy the experience!
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <span className='rating-number'>5.0</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className='slide-item-inner'>
                  <div className='header'>
                    <span className='thumb'>
                      <span>PT</span>
                    </span>
                    <h5 className='user-name'>Polina Tolstova</h5>
                    <p className='user-role'>US</p>
                  </div>
                  <div className='content'>
                    <p>
                      I love that their chat is 24/7, there are always people available to answer your questions! Only
                      four and not five stars because I haven’t ordered and used their storage and shipped my luggage
                      yet!
                    </p>
                  </div>
                  <div className='user-rating'>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='fas fa-star'></i>
                    <i className='far fa-star'></i>
                    <span className='rating-number'>4.0</span>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
    </>
  )
}

export default Reviews
