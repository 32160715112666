import React from 'react'
import Meta from '@/components/common/Meta'
import Reviews from '@/components/home/Reviews'
import Features from '@/components/home/Features'
import WhyDifferent from '@/components/home/WhyDifferent'
import Promotion from '@/components/sections/Promotion'
import CustomizedSolution from '@/components/home/CustomizedSolution'
import StorageFeatures from '@/components/home/StorageFeatures'
import HomeBanner from '@/components/banners/HomeBanner'
import { META_CONTENTS } from '@/v1/constants/seo'

function Home() {
  return (
    <>
      <Meta
        title={META_CONTENTS.home.title}
        keywords={META_CONTENTS.home.keywords}
        description={META_CONTENTS.home.description}
      />

      <HomeBanner />

      <Features />

      <WhyDifferent />

      <CustomizedSolution />

      <StorageFeatures />

      <Reviews />

      <Promotion />
    </>
  )
}

export default Home
