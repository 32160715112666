import React from 'react'
import Link from 'next/link'
import { solutions_output } from '@/components/home/Contents'

function CustomizedSolution() {
  return (
    <>
      <section className='coustomized-solution-section pt-16 pb-11 sm-mx:pt-8 sm-mx:pb-6'>
        <div className='container'>
          <div className='row justify-center'>
            <div className='w-full text-center'>
              <div className='section-title effect text-center'>
                <h2>We Brought Customized Solution</h2>
                <p>
                  You can take advantage from our smart self storage solutions! No matter where you live or how many
                  belongings you have!
                </p>
              </div>
            </div>
          </div>
          <div className='row justify-between pt-12'>{solutions_output}</div>
          <div className='row justify-center mt-12 sm-mx:mt-6'>
            <Link href={`/book-storage-facility`} className='btn btn-primary get-started shadow'>
              Get Started
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default CustomizedSolution
