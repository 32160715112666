import React from 'react'
import PropTypes from 'prop-types'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'

library.add(faCheck)

const FeatureBox = function (props) {
  const title = props.title || ''
  const content = props.content || ''
  const icon = props.icon && <img src={props.icon} alt={title} />
  const className = 'feature-box justify-center ' + props.className || ''
  const readMore = !!props.readMore
  const readMoreTo = props.readMoreTo || '/'
  const readMoreText = props.readMoreText || ''
  const readMoreClass = props.readMoreClass || ''

  // const getContent = () => {
  //     if (content instanceof Function) {
  //         return content();
  //     } else {
  //         return <p dangerouslySetInnerHTML={{__html: content}}></p>;
  //     }
  // }

  return (
    <div className={className}>
      <div className='feature-box-icon'>{icon}</div>
      <div className='feature-box-content'>
        <h3 className='title'>{title}</h3>
        {content && <p dangerouslySetInnerHTML={{ __html: content }}></p>}
        {props?.children}
        {readMore && (
          <Link href={readMoreTo} className={readMoreClass}>
            {readMoreText}
          </Link>
        )}
      </div>
    </div>
  )
}

FeatureBox.propTypes = {
  title: PropTypes.string.isRequired
  // content: PropTypes.string
}

export default FeatureBox
