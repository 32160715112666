import React from 'react'
import Link from 'next/link'
import { features_output } from '@/components/home/Contents'

function Features() {
  return (
    <>
      <section className='features-section pt-24 pb-14 md-mx:pt-12 sm-mx:pt-10 sm-mx:pb-10'>
        <div className='container-fluid'>
          <div className='row justify-center'>
            <div className='w-full text-center'>
              <div className='section-title effect text-center'>
                <h2>How Smart Storage Works</h2>
                <p>A new kind of smart storage that you can "Trust On"</p>
              </div>
            </div>
          </div>
          <div className='row justify-between pt-12'>{features_output}</div>
          <div className='row justify-center mt-12 sm-mx:mt-1'>
            <Link href={`/book-storage-facility`} className='btn btn-primary get-started shadow'>
              Get Started
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
