import React from 'react'
import { getBanner } from '@/components/home/Contents'
import useResponsive from '@/hooks/responsive'
import { useSelector } from 'react-redux'
import { cn } from '@/lib/utils'

function HomeBanner() {
  const authReducer = useSelector(state => state.authReducer)

  const { xs, sm, md, lg, xl, xxl } = useResponsive()

  return (
    <>
      <div className={'main-banner'}>
        <div
          className={cn(`lg-mn:min-h-[512px] with-banner home-banner pt-[114px] xs:pt-[90px]`, {
            'pt-[147px]': authReducer?.auth
          })}
        >
          {sm || xs
            ? getBanner().mobileBanner
            : md
              ? getBanner().tabBanner
              : lg || xl || xxl
                ? getBanner().desktopBanner
                : ''}
        </div>
        {getBanner().bannerAfter}
      </div>
    </>
  )
}

export default HomeBanner
