import React from 'react'
import Link from 'next/link'
import { storage_features_output } from '@/components/home/Contents'
import { assets } from '@/helpers/common'

function StorageFeatures() {
  return (
    <>
      <section className='storage-features-section pt-16 pb-11 sm-mx:pt-6 sm-mx:pb-6'>
        <div className='container'>
          <div className='row justify-center'>
            <div className='w-full text-center'>
              <div className='section-title effect text-center'>
                <h2>Get More With Us</h2>
                <p>We are more flexible than others in the market</p>
              </div>
            </div>
          </div>
          <div className='row justify-center pt-12'>
            <table className='storage-feature-table w-11/12 md-mx:w-full'>
              <thead>
                <tr>
                  <td>Services</td>
                  <td className='bg-white'>
                    <img src={assets('images/storage-feature-table-logo.png')} alt={'storage-feature'} />
                  </td>
                  <td>Clutter</td>
                  <td>Public Storage</td>
                </tr>
              </thead>
              <tbody>{storage_features_output}</tbody>
            </table>
          </div>
          <div className='row justify-center mt-12'>
            <Link href={`/book-storage-facility`} className='btn btn-primary get-started shadow'>
              Get Started
            </Link>
          </div>
        </div>
      </section>
    </>
  )
}

export default StorageFeatures
